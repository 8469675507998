import React, { useContext, useState, useEffect } from 'react';
import styles from './styles/CreateSidebar.module.css';
import { FaShapes, FaIndent, FaSortNumericDown, FaKey, FaCog, FaList, FaSignOutAlt,
    FaCopy, FaEye, FaSearch, FaExclamationCircle, FaCheckCircle, FaPaperPlane } from 'react-icons/fa';
import CreateSituation from './CreateSituation';
import SituationsAndGroups from './SituationsAndGroups';
import SetOrder from './SetOrder';
import CreateKey from './CreateKey';
import { useNavigate } from 'react-router-dom';
import CanvasContext from '../../../context/CanvasContext';
import TableOfContents from './TableOfContents';
import { Tooltip } from 'react-tooltip';
import { checkValidity } from '../../../utils/utils';
import { useScenario } from '../../../context/ScenarioContext';
import axios from 'axios';

const CreateSidebar = ({ isMobile, renderEditSidebar, setRenderEditSidebar }) => {
    const { scenario, hasToc, setHasToc, showTocEditor, setShowTocEditor, copyNode, selectedNodeIds, graphErrors,
        setGraphErrors, nodes, edges, handlePublish, setScenario,
    } = useContext(CanvasContext);

    const { setCurrentSituationId } = useScenario();
    const [activePanel, setActivePanel] = useState(null); // 'createSituation' or 'situationsList'
    const [isFullyExpanded, setIsFullyExpanded] = useState(false); // Track when expansion is complete
    const navigate = useNavigate();
    
    const isButtonDisabled = !selectedNodeIds || selectedNodeIds.length < 1;

    const togglePanel = (panelName) => {
        setActivePanel(prev => (prev === panelName ? null : panelName));
        if (isMobile && setRenderEditSidebar) {
            setRenderEditSidebar(false); // Hide EditSidebar on mobile when CreateSidebar is expanded
        }
    };

    const toggleCreateSituation = () => {
        togglePanel('createSituation');
    };

    const toggleSituationsList = () => {
        togglePanel('situationsList');
    };

    const toggleSetOrder = () => {
        togglePanel('setOrder');
    };

    const toggleCreateKey = () => {
        togglePanel('createKey');
    };

    const handleSettingsClick = () => {
        navigate(`/create/${scenario?._id}`);
    };

    const createTableOfContents = async () => {
        if (hasToc) {
            setShowTocEditor(true);
        } else {
            try {
                const response = await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${scenario._id}/create-toc`);
                if (response.status === 200) {
                    // Update the local scenario state with the new table of contents
                    setScenario(response.data);
                    setHasToc(true);
                    setShowTocEditor(true);
                } else {
                    console.error("Failed to update scenario.");
                }
            } catch (err) {
                console.error("An error occurred while updating the scenario:", err);
            }
        }
    };
    

    const handleClickPreview = () => {
        setCurrentSituationId(scenario._id, null);
        navigate(`/create/${scenario._id}/preview`);
    }

    const handleExitClick = () => {
        navigate('/home');
    };

    // Handle when the transition ends
    const handleTransitionEnd = () => {
        if (activePanel) {
            setIsFullyExpanded(true); // Panel is fully expanded, render content
        } else {
            setIsFullyExpanded(false); // Reset when collapsing
        }
    };

    // Effect to close any expanded panel when renderEditSidebar becomes true
    useEffect(() => {
        if (renderEditSidebar) {
            setActivePanel(null);
        }
    }, [renderEditSidebar]);

    const collapsePanel = () => {
        setActivePanel(null);
    };

    return (
        <>
            <div className={styles.container}>
                {/* Narrow Sidebar with Icons */}
                <div className={styles.iconSidebar}>
                    <div
                        className={`${styles.iconItem} ${activePanel === 'createSituation' ? styles.activeIcon : ''}`}
                        onClick={toggleCreateSituation}
                        data-tooltip-id="tooltip"
                        data-tooltip-content="Create Situation"
                    >
                        <FaShapes />
                    </div>
                    <div
                        className={`${styles.iconItem} ${activePanel === 'situationsList' ? styles.activeIcon : ''}`}
                        onClick={toggleSituationsList}
                        data-tooltip-id="tooltip"
                        data-tooltip-content="Situations and Groups"
                    >
                        <FaIndent />
                    </div>
                    <div
                        className={`${styles.iconItem} ${activePanel === 'setOrder' ? styles.activeIcon : ''}`}
                        onClick={toggleSetOrder}
                        data-tooltip-id="tooltip"
                        data-tooltip-content="Set Order"
                    >
                        <FaSortNumericDown />
                    </div>
                    <div
                        className={`${styles.iconItem} ${activePanel === 'createKey' ? styles.activeIcon : ''}`}
                        onClick={toggleCreateKey}
                        data-tooltip-id="tooltip"
                        data-tooltip-content="Keys"
                    >
                        <FaKey />
                    </div>
                    <div
                        className={styles.iconItem}
                        onClick={handleSettingsClick}
                        data-tooltip-id="tooltip"
                        data-tooltip-content="Scenario Settings"
                    >
                        <FaCog />
                    </div>
                    <div
                        className={styles.iconItem}
                        onClick={createTableOfContents}
                        data-tooltip-id="tooltip"
                        data-tooltip-content="Table of Contents"
                    >
                        <FaList />
                    </div>
                    <div
                        className={`${styles.iconItem} ${isButtonDisabled ? styles.disabledIcon : ''}`}
                        onClick={() => {
                            if (!isButtonDisabled) {
                                copyNode(selectedNodeIds[0]);
                                if (!isMobile) {
                                    setRenderEditSidebar(true);
                                }
                            } 
                        }}
                        data-tooltip-id="tooltip"
                        data-tooltip-content="Copy Node"
                        aria-disabled={isButtonDisabled}
                    >
                        <FaCopy />
                    </div>

                    <div
                        className={styles.iconItem}
                        onClick={handleClickPreview}
                        data-tooltip-id="tooltip"
                        data-tooltip-content="Preview Scenario"
                    >
                        <FaEye />
                    </div>
                    <div
                        className={styles.iconItem}
                        onClick={() => {
                            const errors = checkValidity(nodes, edges);
                            setGraphErrors(errors);
                        }}
                        data-tooltip-id="tooltip"
                        data-tooltip-content="Check Validity"
                    >
                        {graphErrors === null ? (
                            <FaSearch />
                        ) : graphErrors.length > 0 ? (
                            <FaExclamationCircle style={{ color: 'red' }} />
                        ) : (
                            <FaCheckCircle style={{ color: 'green' }} />
                        )}
                    </div>
                    <div
                        className={styles.iconItem}
                        onClick={handlePublish}
                        data-tooltip-id="tooltip"
                        data-tooltip-content="Publish"
                    >
                        <FaPaperPlane />
                    </div>
                    <div
                        className={styles.iconItem}
                        onClick={handleExitClick}
                        data-tooltip-id="tooltip"
                        data-tooltip-content="Exit"
                    >
                        <FaSignOutAlt />
                    </div>
                </div>
                {/* Expanded Panel */}
                <div
                    className={`${styles.expandedPanel} ${activePanel ? styles.activePanel : ''}`}
                    onTransitionEnd={handleTransitionEnd}
                >
                    {activePanel === 'createSituation' && <CreateSituation isMobile={isMobile} collapsePanel={collapsePanel} />}
                    {activePanel === 'situationsList' && isFullyExpanded && <SituationsAndGroups setRenderEditSidebar={setRenderEditSidebar} />}
                    {activePanel === 'setOrder' && isFullyExpanded && <SetOrder />}
                    {activePanel === 'createKey' && isFullyExpanded && <CreateKey />}
                </div>
            </div>
            {showTocEditor && (
                <div className={styles.tocContainer}>
                    <TableOfContents />
                </div>
            )}
            <Tooltip
                id="tooltip"
                place="right"
                type="dark"
                effect="solid"
                style={{ zIndex: 9999 }}
            />
        </>
    );
};

export default CreateSidebar;
