import React, { useContext } from 'react';
import styles from './styles/ClassroomCanvasOptions.module.css';
import CanvasContext from '../../../context/CanvasContext';
import { useNavigate, useParams } from 'react-router-dom';

const ClassroomCanvasOptions = () => {
    const {
        classroomStudentsWithLogs,
        assignmentWithSubmissions,
        highlightStudentLog,
        highlightSubmission,
    } = useContext(CanvasContext);

    const navigate = useNavigate();
    const { assignmentId } = useParams();

    const isScenarioMode = !assignmentId;
    const dataSource = isScenarioMode
        ? classroomStudentsWithLogs
        : assignmentWithSubmissions?.submissions;

    if (!dataSource?.length) {
        return (
            <div className={styles.container}>
                <p>No {isScenarioMode ? 'student logs' : 'submissions'} available.</p>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h2>{isScenarioMode ? 'Classroom Logs' : 'Assignment Submissions'}</h2>
            </div>
            <div className={styles.optionsContainer}>
                {isScenarioMode ? (
                    dataSource.map((student) => (
                        <div className={styles.studentSection} key={student._id}>
                            <h3 style={{ color: student.color }}>{student.username}</h3>
                            {student.logs.length === 0 ? (
                                <p>No logs for this scenario.</p>
                            ) : (
                                <ul>
                                    {student.logs.map((log) => {
                                        const created = new Date(log.createdAt).toLocaleString();
                                        return (
                                            <li
                                                key={log._id}
                                                onClick={() => highlightStudentLog(student._id, log)}
                                                className={styles.clickableLog}
                                            >
                                                Log created on {created}
                                            </li>
                                        );
                                    })}
                                </ul>
                            )}
                        </div>
                    ))
                ) : (
                    dataSource.map((submission) => (
                        <div className={styles.submissionSection} key={submission._id}>
                            <h3 style={{ color: submission.color }}>
                                {submission.viewer.username} - Submission
                            </h3>
                            <ul>
                                <li
                                    onClick={() => highlightSubmission(submission)}
                                    className={styles.clickableLog}
                                >
                                    Submitted on {new Date(submission.createdAt).toLocaleString()}
                                </li>
                            </ul>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default ClassroomCanvasOptions;