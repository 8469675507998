import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import styles from './styles/ClassroomAssignmentSubmissions.module.css';
import blankProfileImage from '../../images/profileicon.png';
import { ClassroomContext } from '../../context/ClassroomContext';

const ClassroomAssignmentSubmissions = () => {
    const { assignmentId } = useParams();
    const [assignment, setAssignment] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { isTeacher } = useContext(ClassroomContext);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAssignment = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL_LOCAL}/api/assignments/assignment/${assignmentId}/with-submissions`
                );
                setAssignment(response.data);
                console.log("assignment: ", response.data);
                setLoading(false);
            } catch (error) {
                setError(error.response?.data?.error || 'Failed to load assignment');
                setLoading(false);
            }
        };

        fetchAssignment();
    }, [assignmentId]);

    if (!isTeacher) {
        return (
            <div className={styles.permissionError}>
                You do not have permission to view this page.
            </div>
        );
    }

    if (loading) {
        return <div className={styles.loading}>Loading...</div>;
    }

    if (error) {
        return <div className={styles.error}>{error}</div>;
    }

    if (!assignment) {
        return <div className={styles.error}>No assignment data available</div>;
    }

    const handleVisualizeSubmissions = () => {
        navigate(`/my-classrooms/${assignment.classroom}/l/${assignment?.scenario?._id}/${assignmentId}`, {
            state: { assignmentWithSubmissions: assignment },
        });
    };

    return (
        <div className={styles.container}>
            <div className={styles.assignmentDetails}>
                <h1>{assignment.title || 'Untitled Assignment'}</h1>
                <p className={styles.description}>{assignment.description || 'No description'}</p>
                <p>
                    <strong>Due Date:</strong>{' '}
                    {assignment.dueDate
                        ? new Date(assignment.dueDate).toLocaleDateString()
                        : 'No due date'}
                </p>
                <p>
                    <strong>Scenario:</strong> {assignment.scenario?.title || 'Unnamed Scenario'}
                </p>
                <div className={styles.students}>
                    <strong>Students:</strong>
                    {assignment.students.length > 0 ? (
                        <ul>
                            {assignment.students.map((student) => (
                                <li key={student._id}>
                                    <img
                                        src={student.profilePicture || blankProfileImage}
                                        alt={student.username}
                                        className={styles.profilePicture}
                                    />
                                    {student.username}
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <span> No students assigned</span>
                    )}
                </div>
                <button
                    className={styles.visualizeButton}
                    onClick={handleVisualizeSubmissions}
                >
                    Visualize Submissions
                </button>
            </div>

            <div className={styles.submissions}>
                <h2>Submissions</h2>
                {assignment.submissions.length > 0 ? (
                    <ul className={styles.submissionList}>
                        {assignment.submissions.map((submission) => (
                            <li key={submission._id} className={styles.submissionItem}>
                                <div className={styles.submissionHeader}>
                                    <img
                                        src={submission.viewer.profilePicture || blankProfileImage}
                                        alt={submission.viewer.username}
                                        className={styles.profilePicture}
                                    />
                                    <span className={styles.username}>
                                        {submission.viewer.username}
                                    </span>
                                </div>
                                <p>
                                    <strong>Status:</strong>{' '}
                                    {submission.isCompleted ? 'Completed' : 'In Progress'}
                                </p>
                                <p>
                                    <strong>Submitted:</strong>{' '}
                                    {new Date(submission.createdAt).toLocaleString()}
                                </p>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No submissions yet</p>
                )}
            </div>
        </div>
    );
};

export default ClassroomAssignmentSubmissions;