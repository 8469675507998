import React, { useRef, useEffect } from 'react';
import styles from './styles/TableOfContentsModal.module.css';

const TableOfContentsModal = ({
    isVisible,
    toggleModal,
    itemLabel,
    setItemLabel,
    situations,
    selectedSituation,
    setSelectedSituation,
    handleAdd,
}) => {
    const modalRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                toggleModal();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [toggleModal]);

    if (!isVisible) return null;

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent} ref={modalRef}>
                <h3>Add or Edit Situation</h3>
                <input
                    type="text"
                    placeholder="Enter label"
                    value={itemLabel}
                    onChange={(e) => setItemLabel(e.target.value)}
                    className={styles.inputField}
                />
                <ul className={styles.situationsList}>
                    {situations.map((situation) => (
                        <li
                            key={situation._id}
                            className={`${styles.situationItem} ${selectedSituation && selectedSituation._id === situation._id ? styles.selectedSituation : ''}`}
                            onClick={() => setSelectedSituation(situation)}
                        >
                            {(situation.title && situation.title.trim()) || "Untitled"}
                        </li>
                    ))}
                </ul>
                <div className={styles.buttonGroup}>
                    <button onClick={handleAdd} disabled={!selectedSituation} className={styles.saveButton}>
                        Save and Close
                    </button>
                    <button onClick={toggleModal} className={styles.cancelButton}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TableOfContentsModal;
