import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import styles from './styles/ClassroomAssignmentsModal.module.css';
import { ClassroomContext } from '../../context/ClassroomContext';
import AuthContext from '../../context/AuthContext';
import Loading from '../loaders/Loading';

const ClassroomAssignmentsModal = ({ onClose, assignment }) => {
    const { classroom, createAssignment, updateAssignment } = useContext(ClassroomContext);
    const { userId } = useContext(AuthContext);
    const isEditMode = Boolean(assignment);

    const [title, setTitle] = useState(assignment ? assignment.title : '');
    const [description, setDescription] = useState(assignment ? assignment.description : '');
    const [dueDate, setDueDate] = useState(
        assignment && assignment.dueDate ? assignment.dueDate.substring(0, 10) : ''
    );
    const [selectedScenario, setSelectedScenario] = useState(assignment ? assignment.scenario._id : null);
    const [scenarios, setScenarios] = useState([]);
    const [assignAll, setAssignAll] = useState(true);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [loading, setIsLoading] = useState(true);

    // Fetch teacher's scenarios and update loading state accordingly
    useEffect(() => {
        const fetchScenarios = async () => {
            if (userId) {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/my`
                    );
                    setScenarios(response.data);
                } catch (error) {
                    console.error('Error fetching teacher scenarios:', error);
                } finally {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
            console.log('classroom students: ', classroom.students);
        };
        fetchScenarios();
    }, [userId, classroom.students]);

    // If editing, determine whether all classroom students were assigned.
    useEffect(() => {
        if (isEditMode && classroom && classroom.students) {
            if (assignment.students.length === classroom.students.length) {
                setAssignAll(true);
            } else {
                setAssignAll(false);
                setSelectedStudents(assignment.students.map(student => student._id));
            }
        }
    }, [assignment, classroom, isEditMode]);

    const toggleStudentSelection = (studentId) => {
        if (selectedStudents.includes(studentId)) {
            setSelectedStudents(selectedStudents.filter(id => id !== studentId));
        } else {
            setSelectedStudents([...selectedStudents, studentId]);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!title || !selectedScenario) {
            alert("Title and Scenario are required.");
            return;
        }

        // Set loading state while processing the assignment
        setIsLoading(true);

        const assignmentData = {
            title,
            description,
            dueDate: dueDate ? new Date(dueDate) : null,
            scenario: selectedScenario,
            students: assignAll
                ? classroom.students.map(student => student._id || student.id)
                : selectedStudents
        };

        try {
            if (isEditMode) {
                await updateAssignment(assignment._id, assignmentData);
            } else {
                // Create the assignment
                await createAssignment(classroom._id, assignmentData);

                // Prepare assignees: get full student objects from classroom.students based on assignmentData.students
                const assignees = assignAll
                    ? classroom.students
                    : classroom.students.filter(student =>
                          selectedStudents.includes(student._id || student.id)
                      );

                // Call notification controller for assignment notifications
                await axios.post(`${process.env.REACT_APP_API_URL_LOCAL}/api/notifications/sendAssignmentToStudents`, {
                    assignees,
                    classroomId: classroom._id,
                    classroomName: classroom.name,
                    assignmentTitle: title
                });
            }
            setIsLoading(false);
            onClose();
        } catch (error) {
            console.error('Error saving assignment:', error);
            setIsLoading(false);
        }
    };

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                {loading ? (
                    <Loading />
                ) : (
                    <>
                        <h2>{isEditMode ? 'Edit Assignment' : 'Create Assignment'}</h2>
                        <form onSubmit={handleSubmit} className={styles.form}>
                            <label className={styles.label}>
                                Title:
                                <input
                                    type="text"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    className={styles.input}
                                />
                            </label>
                            <label className={styles.label}>
                                Description:
                                <textarea
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    className={styles.textarea}
                                />
                            </label>
                            <label className={styles.label}>
                                Due Date:
                                <input
                                    type="date"
                                    value={dueDate}
                                    onChange={(e) => setDueDate(e.target.value)}
                                    className={styles.input}
                                />
                            </label>
                            <div className={styles.scenariosSection}>
                                <p className={styles.sectionTitle}>Select Scenario:</p>
                                <div className={styles.scenarioList}>
                                    {scenarios.map((scenario) => (
                                        <div
                                            key={scenario._id}
                                            className={`${styles.scenarioItem} ${selectedScenario === scenario._id ? styles.selected : ''}`}
                                            onClick={() => setSelectedScenario(scenario._id)}
                                        >
                                            {scenario.image && (
                                                <img
                                                    src={scenario.image}
                                                    alt={scenario.title}
                                                    className={styles.scenarioImage}
                                                />
                                            )}
                                            <span>{scenario.title}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className={styles.assignSection}>
                                <label className={styles.checkboxLabel}>
                                    <input
                                        type="checkbox"
                                        checked={assignAll}
                                        onChange={(e) => setAssignAll(e.target.checked)}
                                    />
                                    Assign to all students
                                </label>
                                {!assignAll && classroom && classroom.students && (
                                    <div className={styles.studentsList}>
                                        <p className={styles.sectionTitle}>Select Students:</p>
                                        {classroom.students.map((student) => (
                                            <div
                                                key={student._id || student.id}
                                                className={`${styles.studentItem} ${
                                                    selectedStudents.includes(student._id || student.id)
                                                        ? styles.selected
                                                        : ''
                                                }`}
                                                onClick={() => toggleStudentSelection(student._id || student.id)}
                                            >
                                                {student.profilePicture && (
                                                    <img
                                                        src={student.profilePicture}
                                                        alt={student.username}
                                                        className={styles.studentAvatar}
                                                    />
                                                )}
                                                <span>{student.username}</span>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className={styles.buttonGroup}>
                                <button type="button" onClick={onClose} className={styles.cancelButton}>
                                    Cancel
                                </button>
                                <button type="submit" className={styles.submitButton}>
                                    {isEditMode ? 'Update' : 'Create'}
                                </button>
                            </div>
                        </form>
                    </>
                )}
            </div>
        </div>
    );
};

export default ClassroomAssignmentsModal;
