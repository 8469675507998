import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import styles from './styles/TableOfContents.module.css';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import CanvasContext from "../../../context/CanvasContext";
import TableOfContentsModal from "./TableOfContentsModal";
import useIsMobile from "../../../utils/useIsMobile";

const TableOfContents = () => {

    const { scenario, setScenario, situations, setShowTocEditor } = useContext(CanvasContext);

    const [tableOfContents, setTableOfContents] = useState(
        scenario.tableOfContents || { contents: [] }
    );

    const navigate = useNavigate();
    const [isSectionInputVisible, setIsSectionInputVisible] = useState(false);
    const [isEditingSection, setIsEditingSection] = useState(false);
    const [sectionLabel, setSectionLabel] = useState("");
    const [selectedSituation, setSelectedSituation] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [itemLabel, setItemLabel] = useState('');
    const [currentSectionIndex, setCurrentSectionIndex] = useState(null);
    const [currentContentIndex, setCurrentContentIndex] = useState(null);
    const [currentItemIndex, setCurrentItemIndex] = useState(null);
    const inputRef = useRef(null);
    
    const isMobile = useIsMobile();

    useEffect(() => {
        if (scenario) {
            setTableOfContents(scenario.tableOfContents || null);
        }
    }, [scenario]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target) && !isModalVisible) {
                setCurrentSectionIndex(null);
                setCurrentContentIndex(null);
                setIsEditingSection(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isModalVisible]);

    // Helper function to update both tableOfContents and scenario
    const updateTableOfContents = (newTableOfContents) => {
        setTableOfContents(newTableOfContents);
        setScenario((prevScenario) => ({
            ...prevScenario,
            tableOfContents: newTableOfContents,
        }));
    };

    const createSection = () => {
        setIsSectionInputVisible(true);
    };


    const startEditing = (sectionIndex, contentIndex, label) => {
        setCurrentSectionIndex(sectionIndex);
        setCurrentContentIndex(contentIndex);
        setIsEditingSection(true);
        setSectionLabel(label);
        setIsSectionInputVisible(false);
    };

    const handleSectionEdit = async (e, sectionIndex, contentIndex) => {
        if (e.type === "keypress" && e.key !== "Enter") {
            return;
        }

        const updatedTableOfContents = JSON.parse(JSON.stringify(tableOfContents));
        updatedTableOfContents.contents[contentIndex].sections[sectionIndex].label = sectionLabel;

        try {
            const response = await axios.patch(
                `${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${scenario._id}/update-toc`,
                { tableOfContents: updatedTableOfContents }
            );
            updateTableOfContents(response.data.tableOfContents);
            setCurrentSectionIndex(null);
            setCurrentContentIndex(null);
            setIsEditingSection(false);
            setSectionLabel("");
        } catch (error) {
            console.error(error);
        }
    };


    const toggleModal = (event, sectionIndex, contentIndex, itemIndex, item) => {
        if (event) {
            event.stopPropagation();
        }
        setIsModalVisible(!isModalVisible);
        setItemLabel(item ? item.label : '');
        setSelectedSituation(item ? item.situation : null);
        if (item) {
            const correspondingSituation = situations.find(sit => sit._id === item.situation);
            setSelectedSituation(correspondingSituation);
        }
        setCurrentSectionIndex(sectionIndex);
        setCurrentContentIndex(contentIndex);
        setCurrentItemIndex(itemIndex);
    };


    const handleAdd = async (event, isSection = false) => {
        event.stopPropagation();

        const updatedTableOfContents = JSON.parse(JSON.stringify(tableOfContents));

        if (isSection) {
            // Adding a new section
            const newSection = { label: sectionLabel, items: [] };
            if (updatedTableOfContents.contents.length === 0) {
                updatedTableOfContents.contents.push({ sections: [newSection], items: [] });
            } else {
                updatedTableOfContents.contents[0].sections.push(newSection);
            }

            try {
                const response = await axios.patch(
                    `${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${scenario._id}/update-toc`,
                    { tableOfContents: updatedTableOfContents }
                );
                updateTableOfContents(response.data.tableOfContents);
                setIsSectionInputVisible(false);
                setSectionLabel('');
            } catch (error) {
                console.error(error);
            }
        } else {
            // Adding an item to a section
            const newItem = { label: itemLabel, situation: selectedSituation._id };
            if (currentItemIndex === null) {
                updatedTableOfContents.contents[currentContentIndex].sections[currentSectionIndex].items.push(newItem);
            } else {
                updatedTableOfContents.contents[currentContentIndex].sections[currentSectionIndex].items[currentItemIndex] = newItem;
            }

            try {
                const response = await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${scenario._id}/add-item`, {
                    sectionIndex: currentSectionIndex,
                    contentIndex: currentContentIndex,
                    itemIndex: currentItemIndex,
                    item: newItem,
                });
                setIsModalVisible(false);
                setItemLabel('');
                setSelectedSituation(null);
                setCurrentSectionIndex(null);
                setCurrentContentIndex(null);
                setCurrentItemIndex(null);
                updateTableOfContents(response.data.tableOfContents);
            } catch (error) {
                console.error('Failed to update:', error);
            }
        }
    };

    const handleKeyPress = async (e) => {
        if (e.key === "Enter") {
            await handleAdd(e, true);
        }
    };


    const handleDeleteSection = async (sectionIndex, contentIndex) => {
        try {
            const response = await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${scenario._id}/delete-section`, {
                data: { sectionIndex, contentIndex }
            });
            updateTableOfContents(response.data.tableOfContents);
        } catch (error) {
            console.error('Failed to delete section:', error);
        }
    };

    const handleDeleteItem = async (sectionIndex, contentIndex, itemIndex) => {
        try {
            const response = await axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${scenario._id}/delete-item`, {
                data: { sectionIndex, contentIndex, itemIndex }
            });
            updateTableOfContents(response.data.tableOfContents);
        } catch (error) {
            console.error('Failed to delete item:', error);
        }
    };

        const onDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) {
            return;
        }
        if (source.droppableId === destination.droppableId && source.index === destination.index) {
            return;
        }
        const sourceIds = source.droppableId.split('-');
        const destinationIds = destination.droppableId.split('-');
        const updatedTableOfContents = JSON.parse(JSON.stringify(tableOfContents));
        if (sourceIds[0] === 'content' && destinationIds[0] === 'content') {
            const sourceContentIndex = parseInt(sourceIds[1], 10);
            const destinationContentIndex = parseInt(destinationIds[1], 10);
            if (sourceContentIndex === destinationContentIndex) {
                const [reorderedSection] = updatedTableOfContents.contents[sourceContentIndex].sections.splice(source.index, 1);
                updatedTableOfContents.contents[destinationContentIndex].sections.splice(destination.index, 0, reorderedSection);
            }
        } else if (sourceIds[0] === 'section' && destinationIds[0] === 'section') {
            const sourceContentIndex = parseInt(sourceIds[1], 10);
            const sourceSectionIndex = parseInt(sourceIds[2], 10);
            const destinationContentIndex = parseInt(destinationIds[1], 10);
            const destinationSectionIndex = parseInt(destinationIds[2], 10);
            const [movedItem] = updatedTableOfContents.contents[sourceContentIndex].sections[sourceSectionIndex].items.splice(source.index, 1);
            updatedTableOfContents.contents[destinationContentIndex].sections[destinationSectionIndex].items.splice(destination.index, 0, movedItem);
        }
        axios.patch(`${process.env.REACT_APP_API_URL_LOCAL}/api/scenarios/${scenario._id}/update-toc`, {
            tableOfContents: updatedTableOfContents
        })
            .then(response => {
                updateTableOfContents(response.data.tableOfContents);
            })
            .catch(error => console.error(`Error: ${error}`));
        } ;


    return (
        <div className={styles.container}>
            <div className={styles.contentWrapper}>
                <button onClick={() => setShowTocEditor(false)} className={styles.backButton}>
                    Back
                </button>
                <div className={styles.header}>
                    <div className={styles.title}>Table of Contents</div>
                </div>
                {isModalVisible && (
                    <TableOfContentsModal 
                        isVisible={isModalVisible}
                        toggleModal={toggleModal}
                        itemLabel={itemLabel}
                        setItemLabel={setItemLabel}
                        situations={situations}
                        selectedSituation={selectedSituation}
                        setSelectedSituation={setSelectedSituation}
                        handleAdd={handleAdd}
                    />
                )}
                <div className={styles.controls}>
                    <button onClick={() => setIsSectionInputVisible(true)}>Create Section</button>
                </div>
                {isSectionInputVisible && (
                    <div className={styles.sectionInput}>
                        <input
                            type="text"
                            value={sectionLabel}
                            onChange={(e) => setSectionLabel(e.target.value)}
                            onKeyPress={handleKeyPress}
                            placeholder="Enter section title"
                        />
                        <div className={styles.buttonContainer}>
                            <button onClick={(e) => handleAdd(e, true)}>Create</button>
                            <button onClick={() => {
                                setIsSectionInputVisible(false);
                                setSectionLabel('');
                            }}>
                                Cancel
                            </button>
                        </div>
                    </div>
                )}


                <DragDropContext onDragEnd={onDragEnd}>
                    {tableOfContents && tableOfContents.contents.map((content, contentIndex) => (
                        <Droppable droppableId={`content-${contentIndex}`} key={contentIndex} type="section">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className={styles.sections}
                                >
                                    {content.sections.map((section, sectionIndex) => (
                                        <Draggable key={`section-${contentIndex}-${sectionIndex}`} draggableId={`section-${contentIndex}-${sectionIndex}`} index={sectionIndex}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className={`${styles.section} ${snapshot.isDragging ? styles.dragging : ''}`}
                                                >
                                                    <div className={styles.sectionHeader}>
                                                        {currentSectionIndex === sectionIndex && currentContentIndex === contentIndex && isEditingSection ? (
                                                            <div className={styles.sectionEdit}>
                                                                <input
                                                                    ref={inputRef}
                                                                    type="text"
                                                                    value={sectionLabel}
                                                                    onChange={(e) => setSectionLabel(e.target.value)}
                                                                    onKeyPress={(e) => handleSectionEdit(e, sectionIndex, contentIndex)}
                                                                    placeholder="Section Title"
                                                                />
                                                                <button onClick={(e) => handleSectionEdit(e, sectionIndex, contentIndex)}>
                                                                    Save
                                                                </button>
                                                            </div>
                                                        ) : (
                                                            <span onClick={() => startEditing(sectionIndex, contentIndex, section.label)}>
                                                                {section.label === "" ? "Untitled Section" : section.label}
                                                            </span>
                                                        )}
                                                        <div className={styles.sectionActions}>
                                                            <button onClick={(event) => toggleModal(event, sectionIndex, contentIndex, null, null)}>Add Situation</button>
                                                            <button className={styles.deleteButton} onClick={() => handleDeleteSection(sectionIndex, contentIndex)}>Delete</button>
                                                        </div>
                                                    </div>
                                                    <Droppable droppableId={`section-${contentIndex}-${sectionIndex}`} type="item">
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.droppableProps}
                                                                className={styles.sectionContent}
                                                            >
                                                                {section.items.map((item, itemIndex) => (
                                                                    <Draggable key={itemIndex} draggableId={`item-${contentIndex}-${sectionIndex}-${itemIndex}`} index={itemIndex}>
                                                                        {(provided, snapshot) => (
                                                                            <div
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                className={`${styles.itemContainer} ${snapshot.isDragging ? styles.dragging : ''}`}
                                                                            >
                                                                                <div
                                                                                    className={styles.item}
                                                                                    onClick={(event) => toggleModal(event, sectionIndex, contentIndex, itemIndex, item)}
                                                                                >
                                                                                    {item.label === "" ? "Untitled Situation" : item.label}
                                                                                </div>
                                                                                <button className={styles.deleteButton} onClick={() => handleDeleteItem(sectionIndex, contentIndex, itemIndex)}>Delete</button>
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                ))}
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    ))}
                </DragDropContext>
            </div>
        </div>
    );
    


};

export default TableOfContents;
