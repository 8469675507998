import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles/ClassroomAssignments.module.css';
import { ClassroomContext } from '../../context/ClassroomContext';
import ScenarioCard from '../mainlayout/body/ScenarioCard';
import ClassroomAssignmentsModal from './ClassroomAssignmentsModal';
import ClassroomAssignmentsFilter from './ClassroomAssignmentsFilter';
import AuthContext from '../../context/AuthContext';

const ClassroomAssignments = () => {
    const {
        classroom,
        fetchClassroomAssignments,
        classroomAssignments,
        isTeacher,
        deleteAssignment,
    } = useContext(ClassroomContext);

    const { userId } = useContext(AuthContext);
    const [showModal, setShowModal] = useState(false);
    const [editingAssignment, setEditingAssignment] = useState(null);
    const [filter, setFilter] = useState(null);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (classroom) {
            fetchClassroomAssignments(classroom._id);
        }
    }, [classroom, fetchClassroomAssignments]);

    // Filter assignments for students based on userId
    let baseAssignments = isTeacher
        ? classroomAssignments
        : classroomAssignments.filter(assignment =>
            assignment.students.some(student => student._id === userId)
        );

    if (filter) {
        baseAssignments = baseAssignments.filter((assignment) => {
            const dateValue = assignment[filter.dateType];
            if (!dateValue) return false;
            
            // Create a date from the assignment date and normalize it to a local date (year, month, day)
            const assignmentDate = new Date(dateValue);
            const assignmentLocal = new Date(
                assignmentDate.getFullYear(),
                assignmentDate.getMonth(),
                assignmentDate.getDate()
            );

            // Parse filter.startDate as a local date
            const [startYear, startMonth, startDay] = filter.startDate.split('-').map(Number);
            const startLocal = new Date(startYear, startMonth - 1, startDay);

            // Parse filter.endDate as a local date and set to the end of the day
            const [endYear, endMonth, endDay] = filter.endDate.split('-').map(Number);
            const endLocal = new Date(endYear, endMonth - 1, endDay, 23, 59, 59, 999);

            return assignmentLocal >= startLocal && assignmentLocal <= endLocal;
        });
    }

    

    const openCreateModal = () => {
        setEditingAssignment(null); // creation mode
        setShowModal(true);
    };

    const openEditModal = (assignment) => {
        setEditingAssignment(assignment);
        setShowModal(true);
    };

    const handleDelete = async (assignmentId) => {
        if (window.confirm('Are you sure you want to delete this assignment?')) {
            await deleteAssignment(assignmentId);
        }
    };

    const handleViewSubmissions = (assignment) => {
        navigate(`/my-classrooms/${classroom._id}/assignments/${assignment._id}`);
    };

    const openFilterModal = () => {
        setShowFilterModal(true);
    };

    const closeFilterModal = () => {
        setShowFilterModal(false);
    };

    const applyFilter = (newFilter) => {
        setFilter(newFilter);
        closeFilterModal();
    };

    const clearFilter = () => {
        setFilter(null);
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                {isTeacher && (
                    <button className={styles.createButton} onClick={openCreateModal}>
                        Create Assignment
                    </button>
                )}
                <button className={styles.filterButton} onClick={openFilterModal}>
                    {filter ? 'Edit Filter' : 'Filter'}
                </button>
                {filter && (
                    <>
                        <span className={styles.filterDetails}>
                            Filter: {filter.dateType === 'createdAt' ? 'Assigned On' : 'Due Date'} from {filter.startDate} to {filter.endDate}
                        </span>
                        <button className={styles.clearFilterButton} onClick={clearFilter}>
                            Clear Filter
                        </button>
                    </>
                )}
            </div>
            {baseAssignments.length === 0 ? (
                <p>No assignments found.</p>
            ) : (
                baseAssignments.map((assignment) => (
                    <div key={assignment._id} className={styles.assignmentCard}>
                        <div className={styles.assignmentHeader}>
                            <span className={styles.assignedDate}>
                                Assigned On: {new Date(assignment.createdAt).toLocaleDateString()}
                            </span>
                            {assignment.dueDate && (
                                <span className={styles.dueDate}>
                                    Due: {new Date(assignment.dueDate).toLocaleDateString()}
                                </span>
                            )}
                        </div>
                        <h3 className={styles.assignmentTitle}>{assignment.title}</h3>
                        <p className={styles.assignmentDescription}>{assignment.description}</p>
                        <div className={styles.scenarioContainer}>
                            <ScenarioCard scenario={assignment.scenario} isAuthor={false} />
                        </div>
                        <div className={styles.studentsContainer}>
                            {assignment.students &&
                                assignment.students.length > 0 &&
                                assignment.students.map((student) => (
                                    <div key={student._id || student.id} className={styles.student}>
                                        {student.profilePicture && (
                                            <img
                                                src={student.profilePicture}
                                                alt={student.username}
                                                className={styles.studentAvatar}
                                            />
                                        )}
                                        <span className={styles.studentName}>{student.username}</span>
                                    </div>
                                ))}
                        </div>
                        {isTeacher && (
                            <div className={styles.teacherActions}>
                                <button
                                    className={styles.editButton}
                                    onClick={() => openEditModal(assignment)}
                                >
                                    Edit
                                </button>
                                <button
                                    className={styles.deleteButton}
                                    onClick={() => handleDelete(assignment._id)}
                                >
                                    Delete
                                </button>
                                <button
                                    className={styles.viewSubmissionsButton}
                                    onClick={() => handleViewSubmissions(assignment)}
                                >
                                    View Submissions
                                </button>
                            </div>
                        )}
                    </div>
                ))
            )}
            {showModal && (
                <ClassroomAssignmentsModal onClose={() => setShowModal(false)} assignment={editingAssignment} />
            )}
            {showFilterModal && (
                <ClassroomAssignmentsFilter
                    filter={filter}
                    onApply={applyFilter}
                    onClose={closeFilterModal}
                />
            )}
        </div>
    );
};

export default ClassroomAssignments;
