import React from 'react';
import { transformIframes } from '../../../utils/utils';
import styles from './styles/SituationBody.module.css';
import MediaPlayer from '../../../utils/MediaPlayer';

const SituationBody = ({ situation }) => {
    


    // Determine autoplay behavior based on the `.autoplay` attribute
    const shouldAutoplay = situation.autoplay === true;

    return (
        <div className={styles.container}>
            {situation.audio && (
                <div className={styles.mediaContainer}>
                    <MediaPlayer
                        key={situation.audio}  // Force re-mount when the source changes
                        src={situation.audio}
                        autoplay={shouldAutoplay}
                    />
                    {situation.audioLabel && situation.audioLabel.trim() !== "" && (
                        <div className={styles.mediaLabel}>{situation.audioLabel}</div>
                    )}
                </div>
            )}


            {situation.image && (
                <div className={styles.imageContainer}>
                    <img src={situation.image} alt={situation.title} className={styles.image} />
                </div>
            )}
            <div
                className={styles.text}
                dangerouslySetInnerHTML={{ __html: transformIframes(situation.text) }}
            />
        </div>
    );
};

export default SituationBody;
