import React, { useState, useEffect } from 'react';
import styles from './styles/ClassroomAssignmentsFilter.module.css';

const ClassroomAssignmentsFilter = ({ filter, onApply, onClose }) => {
    const [dateType, setDateType] = useState('createdAt');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    useEffect(() => {
        if (filter) {
            setDateType(filter.dateType);
            setStartDate(filter.startDate);
            setEndDate(filter.endDate);
        }
    }, [filter]);

    const handleSubmit = (e) => {
        e.preventDefault();
        // basic validation could be added here
        onApply({ dateType, startDate, endDate });
    };

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <h2>Filter Assignments</h2>
                <form onSubmit={handleSubmit}>
                    <div className={styles.inputGroup}>
                        <label>
                            <input
                                type="radio"
                                name="dateType"
                                value="createdAt"
                                checked={dateType === 'createdAt'}
                                onChange={() => setDateType('createdAt')}
                            />
                            Assigned On
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="dateType"
                                value="dueDate"
                                checked={dateType === 'dueDate'}
                                onChange={() => setDateType('dueDate')}
                            />
                            Due Date
                        </label>
                    </div>
                    <div className={styles.inputGroup}>
                        <label>
                            Start Date:
                            <input
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                required
                            />
                        </label>
                    </div>
                    <div className={styles.inputGroup}>
                        <label>
                            End Date:
                            <input
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                required
                            />
                        </label>
                    </div>
                    <div className={styles.buttonGroup}>
                        <button type="submit" className={styles.applyButton}>
                            Apply Filter
                        </button>
                        <button type="button" className={styles.cancelButton} onClick={onClose}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ClassroomAssignmentsFilter;
