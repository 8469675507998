import React, { useState, useContext, useEffect, useRef } from 'react';
import styles from './styles/Navbar.module.css';
import NavbarMenu from './NavbarMenu';
import AuthContext from '../../../context/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import NavbarNotifications from './NavbarNotifications';
import { io } from 'socket.io-client';
import NavbarSearch from "./NavbarSearch"
import AuthModal from '../../auth/AuthModal';
import axios from 'axios';
import { FaBars, FaBell, FaHome, FaPlus, FaSearch } from 'react-icons/fa'; // Import the search icon
import CreateScenarioModal from './CreateScenarioModal';

const socket = io(`${process.env.REACT_APP_API_URL_LOCAL}`);

const Navbar = () => {
    const { userId, loggedIn, user, loading } = useContext(AuthContext);
    const [showMenu, setShowMenu] = useState(false);
    const [showNotifications, setShowNotifications] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [authModalState, setAuthModalState] = useState('login');
    const [acceptInvite, setAcceptInvite] = useState(false);
    const [isSearchExpanded, setIsSearchExpanded] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);
    const navigate = useNavigate();
    const location = useLocation();

    const notificationsWrapperRef = useRef(null);
    const menuWrapperRef = useRef(null);

    const [notifications, setNotifications] = useState(user ? user.notifications : []);
    const [hasNewNotification, setHasNewNotification] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 900);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        if (location.state) {
            const { showModal, authModalState, acceptInvite } = location.state;
            setShowModal(showModal);
            setAuthModalState(authModalState);
            setAcceptInvite(acceptInvite);
        }
    }, [location.state]);

    // Function to refetch notifications from the server
    const fetchNotifications = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL_LOCAL}/api/notifications/get-notifications`);
            // setNotifications(res.data.notifications);
            // setHasNewNotification(res.data.notifications.some(n => !n.seen));
        } catch (error) {
            console.error('Error fetching notifications:', error);
        }
    };

    useEffect(() => {
        if (user && user.notifications) {
            setNotifications(user.notifications);
            setHasNewNotification(user.notifications.some(notification => !notification.seen));
        }
    }, [user]);

    const handleNewNotification = (notification) => {
        console.log("CLIENT HAS RECEIVED THE NOTIFICATION FROM SOCKET!!");
        setNotifications((prevNotifications) => {
            const updatedNotifications = [notification, ...prevNotifications];
            setHasNewNotification(updatedNotifications.some(notif => !notif.seen));
            return updatedNotifications;
        });
        // Optionally, refetch after a short delay to allow cache update to occur on the server:
        // setTimeout(fetchNotifications, 1000);
    };
    
    

    useEffect(() => {
        if (userId) {
            socket.emit('join', userId);
            socket.on('notification', handleNewNotification);

            return () => {
                socket.off('notification', handleNewNotification);
            };
        }
    }, [userId]);

    const handleOpenCreateModal = () => {
        setShowCreateModal(true);
    };

    const handleCloseCreateModal = () => {
        setShowCreateModal(false);
    };

    const handleClickNotifications = () => {
        setShowNotifications(!showNotifications);
        setShowMenu(false);
        setHasNewNotification(false);
    };

    const handleClickMenu = () => {
        setShowMenu(!showMenu);
        setShowNotifications(false);
    };

    // Use useEffect to handle clicks outside the notifications dropdown
    useEffect(() => {
        const handleClickOutsideNotifications = (event) => {
            if (notificationsWrapperRef.current && !notificationsWrapperRef.current.contains(event.target)) {
                setShowNotifications(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutsideNotifications);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideNotifications);
        };
    }, []);

    // Use useEffect to handle clicks outside the menu dropdown
    useEffect(() => {
        const handleClickOutsideMenu = (event) => {
            if (menuWrapperRef.current && !menuWrapperRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutsideMenu);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideMenu);
        };
    }, []);

    const handleClickLogIn = () => {
        setShowModal(!showModal);
        setShowNotifications(false);
        setShowMenu(false);
        setAuthModalState('login');
    }

    const handleClickSignUp = () => {
        setShowModal(!showModal);
        setShowNotifications(false);
        setShowMenu(false);
        setAuthModalState('sign up');
    }

    const toggleSearch = () => {
        setIsSearchExpanded(!isSearchExpanded);
    };


    if (loading) {
        return (
            <>
                { isMobile ? (
                    <div className={styles.navbarContainer}>
                        <img
                            className={styles.logo}
                            src={require('../../../images/logo_teal_background.png')}
                            alt="Home"
                            onClick={loggedIn === false ? () => navigate('/') : () => navigate('/home')}
                        />
                    </div>
                ) : (
                    <div className={styles.navbarContainer}>
                        <img
                            className={styles.logo}
                            src={require('../../../images/LOGO_aScenario.png')}
                            alt="Home"
                            onClick={loggedIn === false ? () => navigate('/') : () => navigate('/home')}
                        />
                    </div>
                )}
            </>
        );
    }

    return (
        <div className={styles.navbarContainer}>
            {isMobile ? (
                <>
                    <div className={styles.logoContainer}>
                        <img
                            className={styles.logo}
                            src={require('../../../images/logo_teal_background.png')}
                            alt="Home"
                            onClick={loggedIn === false ? () => navigate('/') : () => navigate('/home')}
                        />
                    </div>
                    {isSearchExpanded ? (
                        <div className={styles.expandedSearchContainer}>
                            <NavbarSearch isMobile={isMobile} setIsSearchExpanded={setIsSearchExpanded} />
                        </div>
                    ) : null }
                </>
            ) : (
                <>
                    <div className={styles.logoContainer}>
                        <img
                            className={styles.logo}
                            src={require('../../../images/LOGO_aScenario.png')}
                            alt="Home"
                            onClick={loggedIn === false ? () => navigate('/') : () => navigate('/home')}
                        />
                    </div>
                    <div className={styles.navbarSearchContainer}>
                        <NavbarSearch />
                    </div>
                </>
            )}
    
            <div className={styles.navbarItemContainer}>
                {(loggedIn && !acceptInvite) ? (
                    <>
                        {isMobile && (
                            <div className={styles.navbarItem} onClick={toggleSearch}>
                                <FaSearch className={styles.icon} />
                            </div>
                        )}
                        {/* <div className={styles.navbarItem} onClick={loggedIn === false ? () => navigate('/') : () => navigate('/home')}>
                            <FaHome className={styles.icon} />
                            {!isMobile && <span className={styles.iconLabel}>Home</span>}
                        </div> */}
                        <div className={styles.navbarItem} onClick={handleOpenCreateModal}>
                            <FaPlus className={styles.icon} />
                            {!isMobile && <span className={styles.iconLabel}>Create</span>} {/* Show label only if not mobile */}
                        </div>
                        <div ref={notificationsWrapperRef}>
                            <div className={styles.navbarItem} onClick={handleClickNotifications}>
                                <FaBell className={styles.icon} />
                                {hasNewNotification && <span className={styles.notificationBadge}>!</span>}
                                {!isMobile && <span className={styles.iconLabel}>Notifications</span>}
                            </div>
                            {showNotifications && (
                                <NavbarNotifications
                                    notifications={notifications}
                                    setNotifications={setNotifications}
                                    setShowNotifications={setShowNotifications}
                                />
                            )}
                        </div>
                        <div ref={menuWrapperRef}>
                            <div className={styles.navbarItem} onClick={handleClickMenu}>
                                <FaBars className={styles.icon} />
                                {!isMobile && <span className={styles.iconLabel}>Menu</span>}
                            </div>
                            {showMenu && (
                                <NavbarMenu
                                    setShowMenu={setShowMenu}
                                    user={user}
                                />
                            )}
                        </div>
                    </>
                ) : (
                    !loading && (
                        <>
                            {isMobile && (
                                <div className={styles.navbarItem} onClick={toggleSearch}>
                                    <FaSearch className={styles.icon} />
                                </div>
                            )}
                            <button className={styles.login} onClick={handleClickLogIn}>Log In</button>
                            <button className={styles.signup} onClick={handleClickSignUp}>Sign Up</button>
                        </>
                    )
                )}
            </div>
            {showModal && (
                <AuthModal 
                    showModal={showModal} setShowModal={setShowModal} 
                    authModalState={authModalState} setAuthModalState={setAuthModalState}
                    acceptInvite={acceptInvite} setAcceptInvite={setAcceptInvite}
                />
            )}
            <CreateScenarioModal
                isOpen={showCreateModal}
                onClose={handleCloseCreateModal}
            />
        </div>
    );
    
    
};

export default Navbar;