import React, { useContext, useState, useEffect } from 'react';
import CanvasContext from '../../../context/CanvasContext';
import EditSidebarTabs from './EditSidebarTabs';
import SituationsList from './SituationsList';
import UploadModal from './UploadModal';
import textStyles from './styles/EditSidebarText.module.css';
import settingsStyles from './styles/EditSidebarSettings.module.css';
import DeleteModal from './DeleteModal';
import useIsMobile from '../../../utils/useIsMobile';
import StyledSwitch from './StyledSwitch';
import MediaPlayer from '../../../utils/MediaPlayer';
import { getMediaSrc } from '../../../utils/utils';
import TextEditor from '../../texteditor/TextEditor';

const EditGroup = ({ setRenderEditSidebar }) => {
    const {
        updateGroup,
        setSelectedGroup,
        selectedGroup,
        activeEditTab,
        situations,
        deleteGroup,
        nodes,
        updateNodes,
    } = useContext(CanvasContext);

    const [title, setTitle] = useState(selectedGroup.title || '');
    const [text, setText] = useState(selectedGroup.text || '');
    const [groupSituations, setGroupSituations] = useState([]);
    const [image, setImage] = useState(selectedGroup.image || null);
    const [audio, setAudio] = useState(selectedGroup.audio || null);
    const [audioLabel, setAudioLabel] = useState(selectedGroup.audioLabel || "");
    const [color, setColor] = useState(selectedGroup.color || '#ffffff');
    const [autoplay, setAutoplay] = useState(selectedGroup.autoplay || false);

    // Modal control
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [uploadType, setUploadType] = useState('');
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    //Tabs
    const [activeSubTab, setActiveSubTab] = useState('text');

    const isMobile = useIsMobile();


    useEffect(() => {
        setTitle(selectedGroup.title || '');
        setText(selectedGroup.text || '');
        setImage(selectedGroup.image || null);
        setAudio(selectedGroup.audio || null);
        setAudioLabel(selectedGroup.audioLabel || "");
        setColor(selectedGroup.color || '#ffffff');
        setAutoplay(selectedGroup.autoplay || false);
    
        const filteredSituations = situations.filter(
            situation => situation.situationGroup && situation.situationGroup._id === selectedGroup._id
        );
        setGroupSituations(filteredSituations);
    }, [selectedGroup, situations]);
    

    const openModal = (type) => {
        setUploadType(type);
        setIsModalOpen(true);
    };
    

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleTitleBlur = async () => {
        if (title !== selectedGroup.title) {
            try {
                // Update the group title
                await updateGroup({ title: title });
            } catch (error) {
                console.error("Error updating group: ", error);
            }
        }
    };

    const handleAudioLabelChange = (event) => {
        setAudioLabel(event.target.value);
    };

    const handleAudioLabelBlur = async () => {
        if (audioLabel !== selectedGroup.audioLabel) {
            try {
                await updateGroup({ audioLabel: audioLabel });
            } catch (error) {
                console.error('Error updating situation or canvas: ', error);
            }
        }
    };

    const handleTextEditorChange = (data) => {
        setText(data);
    };

    const handleTextEditorBlur = async () => {
        if (text !== selectedGroup.text) {
            try {
                // Update the group text
                await updateGroup({ text: text });
            } catch (error) {
                console.error("Error updating group text: ", error);
            }
        }
    };

    const handleDeleteGroup = () => {
        setIsDeleteModalOpen(true);  // Open the DeleteModal
    };

    const confirmDeleteGroup = async () => {
        try {
            await deleteGroup(selectedGroup._id, groupSituations);
            setIsDeleteModalOpen(false); // Close modal after deletion
            if(isMobile) {
                setRenderEditSidebar(false);
            }
        } catch (error) {
            console.error('Error deleting group:', error);
        }
    };

    const cancelDeleteGroup = () => {
        setIsDeleteModalOpen(false);  // Close the modal without deleting
    };

    const handleColorChange = async (newColor) => {
        setColor(newColor);
        try {
            await updateGroup({ color: newColor });

            // Update nodes that correspond to situations in the group
            const updatedNodes = nodes.map(node =>
                groupSituations.some(situation => situation._id === node.id)
                    ? { ...node, data: { ...node.data, fillColor: newColor } }
                    : node
            );
            await updateNodes(updatedNodes);
        } catch (error) {
            console.error('Error updating group or node colors:', error);
        }
    };

    const handleSwitchChange = async (setting, value) => {
        try {
            let updates = { [setting]: value };
            // Apply local state changes
            if (updates.hasOwnProperty('autoplay')) setAutoplay(value);
            await updateGroup(updates); // Delegate the rest to updateSituation in the context
        } catch (error) {
            console.error(`Error updating ${setting}: `, error);
        }
    };
    
    
    return (
        <>
            <EditSidebarTabs editType="group" />
            {activeEditTab === '1' && (
                <>
                    {/* Subtabs */}
                    <div className={textStyles.subTabsContainer}>
                        <div
                            className={`${textStyles.subTab} ${activeSubTab === 'text' ? textStyles.activeSubTab : ''}`}
                            onClick={() => setActiveSubTab('text')}
                        >
                            Text
                        </div>
                        <div
                            className={`${textStyles.subTab} ${activeSubTab === 'media' ? textStyles.activeSubTab : ''}`}
                            onClick={() => setActiveSubTab('media')}
                        >
                            Media
                        </div>
                    </div>

                    <div className={textStyles.textContainer}>
                        {/* Subtab Content */}
                        {activeSubTab === 'text' && (
                            <>
                                <input
                                    type="text"
                                    value={title}
                                    onChange={handleTitleChange}
                                    onBlur={handleTitleBlur}
                                    className={textStyles.titleInput}
                                />
                                <TextEditor
                                    initialData={text}
                                    onChange={handleTextEditorChange}
                                    onBlur={handleTextEditorBlur}
                                />
                            </>
                        )}
                        {activeSubTab === 'media' && (
                            <>
                                {image && (
                                    <div className={textStyles.mediaPreview}>
                                        <img src={image} alt="Uploaded" className={textStyles.imagePreview} />
                                    </div>
                                )}

                                <div className={textStyles.buttonGroup}>
                                    <button
                                        onClick={() => openModal('image')}
                                        className={`${textStyles.buttonBase} ${textStyles.uploadButton}`}
                                    >
                                        {image ? 'Change Image' : 'Upload Image'}
                                    </button>
                                    {image && (
                                        <button
                                            onClick={async () => {
                                                const updatedGroup = await updateGroup({ image: null });
                                                setImage(null); // Update local state
                                                setSelectedGroup(updatedGroup); // Update selected group to the returned group
                                            }}
                                            className={`${textStyles.buttonBase} ${textStyles.removeButton}`}
                                        >
                                            Remove Image
                                        </button>
                                    )}

                                </div>

                                {audio && (
                                    <>
                                        <input
                                            type="text"
                                            value={audioLabel}
                                            onChange={handleAudioLabelChange}
                                            onBlur={handleAudioLabelBlur}
                                            className={textStyles.titleInput}
                                        />
                                        <div className={textStyles.mediaPreview}>
                                            <MediaPlayer src={getMediaSrc(audio)} autoplay={false} />
                                        </div>

                                    </>
                                )}

                                <div className={textStyles.buttonGroup}>
                                    <button
                                        onClick={() => openModal('audiovideo')}
                                        className={`${textStyles.buttonBase} ${textStyles.uploadButton}`}
                                    >
                                        {audio ? (audio.type && audio.type.startsWith('video') ? 'Change Video' : 'Change Audio') : 'Upload Audio/Video'}
                                    </button>
                                    {audio && (
                                        <button
                                            onClick={async () => {
                                                const updatedGroup = await updateGroup({ audio: null });
                                                setAudio(null); // Update local state
                                                setSelectedGroup(updatedGroup);
                                            }}
                                            className={`${textStyles.buttonBase} ${textStyles.removeButton}`}
                                        >
                                            {audio.type && audio.type.startsWith('video') ? 'Remove Video' : 'Remove Audio'}
                                        </button>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </>
            )}

            {activeEditTab === '2' && (
                <SituationsList situations={groupSituations} />
            )}

            {activeEditTab === '3' && (
                <div className={settingsStyles.settingsContainer}>
                    {/* Color Picker */}
                    <div className={settingsStyles.colorPickerContainer}>
                        <label htmlFor="groupColor">Edit Color</label>
                        <input
                            type="color"
                            id="groupColor"
                            value={color}
                            onChange={(e) => setColor(e.target.value)}
                            onBlur={() => handleColorChange(color)}
                            className={settingsStyles.colorPicker}
                        />
                    </div>

                    <div className={settingsStyles.settingItem}>
                        <label>Autoplay Media</label>
                        <StyledSwitch
                            onChange={(value) => handleSwitchChange('autoplay', value)}
                            checked={autoplay}
                            className={settingsStyles.switch}
                        />
                    </div>

                    {/* Delete Button */}
                    <button onClick={handleDeleteGroup} className={settingsStyles.deleteButton}>
                        Delete Group
                    </button>
                </div>
            )}


            {/** Modals */}
            {isModalOpen && (
                <UploadModal uploadType={uploadType} setIsModalOpen={setIsModalOpen} />
            )}
            {isDeleteModalOpen && (
                <DeleteModal
                    onConfirm={confirmDeleteGroup}
                    onCancel={cancelDeleteGroup}
                    type={'Group'}
                />
            )}
        </>
    );
};

export default EditGroup;
