import React, { useContext, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import styles from './styles/ClassroomViewer.module.css';
import ClassroomCanvasOptions from './ClassroomCanvasOptions';
import CanvasContext from '../../../context/CanvasContext';
import ClassroomCanvas from './ClassroomCanvas';

const ClassroomCanvasViewer = () => {
    const { classroomId, scenarioId, assignmentId } = useParams();
    const location = useLocation();
    const {
        selectedNodeId,
        selectedEdgeId,
        fetchClassroomScenarioLogs,
        fetchAssignmentSubmissions,
        assignmentWithSubmissions,
        setAssignmentWithSubmissions,
    } = useContext(CanvasContext);

    useEffect(() => {
        if (classroomId && scenarioId) {
            if (assignmentId) {
                // Assignment mode
                const passedAssignment = location.state?.assignmentWithSubmissions;
                if (passedAssignment && passedAssignment._id === assignmentId) {
                    setAssignmentWithSubmissions(passedAssignment); // Use passed data
                } else if (!assignmentWithSubmissions || assignmentWithSubmissions._id !== assignmentId) {
                    fetchAssignmentSubmissions(); // Fetch if no valid state or mismatch
                }
            } else {
                // Scenario mode
                fetchClassroomScenarioLogs();
            }
        }
    }, [
        classroomId,
        scenarioId,
        assignmentId,
        location.state,
        fetchClassroomScenarioLogs,
        fetchAssignmentSubmissions,
        assignmentWithSubmissions,
        setAssignmentWithSubmissions,
    ]);

    return (
        <div className={styles.container}>
            <div className={styles.createNodeSidebar}>
                <ClassroomCanvasOptions />
            </div>
            <div className={styles.canvas}>
                <ClassroomCanvas />
            </div>
        </div>
    );
};

export default ClassroomCanvasViewer;