// GroupBody.js

import React from 'react';
import { transformIframes } from '../../../utils/utils';
import styles from './styles/GroupBody.module.css';
import MediaPlayer from '../../../utils/MediaPlayer';

const GroupBody = ({ group }) => {

    // Determine autoplay behavior based on the `.autoplay` attribute
    const shouldAutoplay = group.autoplay === true;

    return (
        <div className={styles.container}>
            {group.audio && (
                <div className={styles.mediaContainer}>
                    <MediaPlayer key={group.audio} src={group.audio} autoplay={shouldAutoplay} />
                    {group.audioLabel && group.audioLabel.trim() !== "" && (
                        <div className={styles.mediaLabel}>{group.audioLabel}</div>
                    )}
                </div>
            )}
            {group.image && (
                <div className={styles.imageContainer}>
                    <img src={group.image} alt={group.title} className={styles.image} />
                </div>
            )}
            <div
                className={styles.text}
                dangerouslySetInnerHTML={{ __html: transformIframes(group.text) }}
            />
        </div>
    );
};

export default GroupBody;
